// Common styles
@import 'styles-common/_a-logo';
@import 'styles-common/_animations';
@import 'styles-common/_variables';
@import 'styles-common/_labels';
@import 'styles-common/_not-logged-in';
@import 'styles-common/_cdn-fonts';
@import 'styles-common/_legibility';

// Core
@import '../../core/scss/helpers/mixins';
@import '../../core/scss/helpers/core-variables';

// Helpers
@import 'styles-desktop/helpers/reset';
@import 'styles-desktop/helpers/helpers';
@import 'styles-desktop/helpers/icons';
@import 'styles-common/helpers';

// Layout
@import 'styles-desktop/layout/layout';

// Components
@import 'copy';
@import 'footer';

//Stripe
@import '../../core/scss/stripe-show-hide';
@import '../../core/scss/infeed-show-hide';

// Partials
@import 'styles-desktop/partials/general';
@import 'styles-desktop/partials/ads';
@import 'styles-desktop/partials/mm-boxes';

@import 'styles-teaser';
@import '../../../src/aftenposten/views-react/Teasers/styles/kort-forklart-desktop';

// Widgets
@import 'styles-desktop/widgets/common';
@import '../../../src/aftenposten/widgets/MostRead/styles/most-read-widget';
@import 'styles-desktop/widgets/infeed';
@import 'styles-desktop/widgets/carousel';
@import 'styles-desktop/widgets/core-carousel';
@import '../../../src/aftenposten/widgets/Oppsummert/styles/oppsummert';
@import '../../../src/aftenposten/widgets/Games/styles/games';
@import 'styles-desktop/widgets/widget-wrapper';
@import 'styles-desktop/widgets/login-widget';
@import 'tag-header';
@import 'sticky-feedback';
@import './../../../src/aftenposten/widgets/FollowedContent/styles/followed-content-widget';
@import 'braze';
@import '../../../src/aftenposten/widgets/LatestNewsWidget/styles/latest-news-widget';

// sections
@import 'styles-hytte';
@import 'styles-historie';

// Hack to remove top margin on fishfinger/stripe
.non-subscriber-stripe > a > div {
  margin-top: 0 !important;
}

// Advertory ads
@import './_ads.scss';

// Native ads / Brand Studio
@import '../../core/scss/helpers/mixins-old';

// Tablet scaling
@import 'tablet';
@import 'next-page';

// Styles for continue as popup
@import '../../core/scss/shame';

// Data controller stripe
@import '../../core/scss/_data-controller.scss';

// Salesposter Service
@import 'push-down-banner';
@import 'bottom-bar';

@import 'section-header';

// Redirect pages
@import 'styles-app-redirect';
