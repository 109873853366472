.teaser {
  background-color: var(--c-light);
  position: relative;
  max-width: 725px;
  margin: 0 auto var(--space-2);

  &.inverted {
    color: var(--c-light);
    background-color: var(--c-dark);
  }

  .teaser-link {
    &:focus {
      outline: auto;
      outline-color: blue;
    }
  }

  &.small {
    padding: var(--space-4);

    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: var(--space-4);
      width: 100%;
    }

    .text-wrapper-small {
      display: flex;
      flex-direction: column;
    }
  }

  &.medium,
  &.large {
    padding: var(--space-4) var(--space-4) var(--space-6);
  }

  &.large {
    padding-bottom: var(--space-8);
  }
}

.top-fullwidth {
  // Topstripe + large image
  .teaser.topstripe.small.top-small-vertical {
    padding-bottom: var(--space-6);

    .container {
      flex-direction: column;
      gap: 0;

      .teaser-image-wrapper {
        width: 100%;
        justify-content: center;
        margin: 0;
        margin-bottom: 16px;
        &::before,
        &::after,
        .series-header {
          top: 24px;
        }
        &::before {
          left: 24px;
        }
        &::after,
        .series-header {
          left: 49px;
        }

        .teaser-image {
          height: 140px;
          width: 100%;
        }
      }
    }
  }
}

//Desktop, suggestion using media querys
@media (min-width: 765px) {
  .teaser {
    width: 100%;
    padding: 0;
    margin: 0;

    &.inverted {
      border-radius: 4px;
      .teaser-link {
        color: var(--c-light);
        &:hover {
          color: var(--c-light);
        }
      }
    }

    &.small.inverted {
      .text-wrapper-small {
        padding: var(--space-6) var(--space-4);
      }
    }

    &.small.inverted.with-image {
      .text-wrapper-small {
        padding: var(--space-4);
      }
    }

    &.medium.inverted.with-image {
      .container {
        padding: 0 var(--space-4) var(--space-4) var(--space-4);
      }
    }

    &.large.inverted.with-image {
      .container {
        padding: 0 var(--space-4) var(--space-8) var(--space-4);
      }
    }

    &:not(.with-image):not(.inverted) {
      .container {
        padding: var(--space-6) 0;
        margin-bottom: 0;
      }
    }

    &.medium:not(.with-image).inverted,
    &.large:not(.with-image).inverted {
      .container {
        padding: var(--space-6) var(--space-4);
      }
    }

    &.small {
      padding: 0;

      .text-wrapper-small {
        flex-basis: 100%;
      }
      .teaser-link {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .illustration .teaser-link {
        display: block;
      }
    }

    &.small,
    &.large {
      .container {
        padding: 0;
      }
    }
    &.large,
    &.medium {
      padding: 0;
      margin: 0;
      height: 100%;
    }
    &.medium {
      .teaser-link {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      .container {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
      }
    }
    &.large {
      .container {
        margin-bottom: 32px;
      }
    }

    &.large.inverted {
      .container {
        margin-bottom: 0;
      }
    }

    &.small.illustration {
      .teaser-link {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .fullwidth {
    .teaser {
      &.medium.with-image {
        .teaser-link {
          display: flex;
          flex-direction: row-reverse;
          gap: 49px;
          justify-content: space-between;

          .container {
            height: initial;
            width: 100%;
          }
          .byline {
            margin-top: auto;
          }
        }

        &.inverted {
          .information {
            margin-top: 0;
          }
        }
        &.inverted {
          .container {
            padding: var(--space-4);
          }
          .teaser-image {
            border-radius: 0 var(--space-1) var(--space-1) 0;
          }
        }

        &.inverted.magazine {
          .teaser-link {
            gap: 0;
          }
        }
      }
    }
  }
  .double {
    .teaser {
      height: auto;
      .container {
        margin-bottom: 16px;
      }
      &:last-child {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          width: 1px;
          height: 100%;
          top: 0;
          left: -24.5px;
          background-color: #e5e6e9;
        }
      }
      &.medium.inverted {
        .teaser-image {
          border-radius: 4px 4px 0 0;
        }
      }
    }
  }
}
