// GENERAL

$containerWidth: 1010px;
$sidebarWidth: 209px;
.content-main-container {
  background-color: $colorBodyBackground;
  width: $containerWidth;
  max-width: 100%;
  margin: 0 auto 0;
  padding: 0 14px 0 13px;
  position: relative;
  z-index: 1;

  .vinklubb-promo-banner {
    max-width: 100%;
    margin-bottom: var(--space-4);
  }

  &__column-with-sidebar {
    display: grid;
    //fixed width because of horseshoe ad
    grid-template-columns: 725px $sidebarWidth;
    gap: 24px;
    padding-bottom: var(--space-6);
    margin-bottom: var(--space-6);

    &.frontpage {
      padding-top: 24px;
      margin-top: 24px;
      border-top: var(--divider-line-ads);
    }
  }
  &__column-main > .content-main-wrapper {
    display: flex;
    flex-direction: column;
  }
  .sidebar-react {
    width: 100%;
    border-left: var(--divider-line);
    padding-left: 24px;

    & > .sidebar-constraint {
      position: relative;
      display: inline-flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;
    }
  }
}
.content-container {
  width: 100%;
  background-color: $colorContentBackground;
  margin: $gridBase auto 0;
  padding: 0;

  &:first-child {
    margin-top: var(--space-5);
  }

  &.top {
    position: relative;
    z-index: 1;
    padding: $gridBase 0;
  }
  &.infeed {
    padding-top: $gridBase;
    padding-bottom: $gridBase;
  }
  &.external {
    background-color: none;
    margin: 0 auto;
    padding: 0;
  }
  &.no-background {
    background-color: $colorBodyBackground;
  }
  &:empty {
    display: none;
  }
  #stripe-wrapper {
    margin-top: $gridBase;
  }
}

// GRID STRUCTURE
.block {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-bottom: var(--divider-line);
  padding-bottom: var(--space-6);
  margin-bottom: var(--space-6);
}

.column {
  &:not(:last-child) {
    margin-right: $gapStandard;
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.col-equal {
  flex-grow: 1;

  &:not(:last-child) {
    margin-right: $gapStandard;
  }
}

.col-1 {
  flex-basis: 32.24%;
}

.col-2 {
  flex-basis: 66.1%;
}

.col-3 {
  flex-basis: 100%;
}

.col-1b {
  flex-basis: 25%;
}
.beta {
  .col-1,
  .col-1b {
    // hide dividers if the element is empty
    &:empty + .column-divider {
      border-color: transparent;
    }
  }
}

.feature .col-1b .row {
  display: block;
}

.col-2b {
  flex-basis: 50%;
}

.col-3b {
  flex-basis: 75%;
}

.col-4b {
  flex-basis: 100%;
}

.col-50pct {
  flex-basis: 50%;
}

.article-container {
  padding: $gapStandard 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.column {
  .row {
    .article-container {
      padding: $gapStandard 0 0;
    }
    &:last-of-type {
      padding-bottom: $gapStandard;
    }
  }
}

.right-divider {
  &:not(:last-child) {
    border-right: var(--divider-line);
    padding-right: var(--space-6);
    margin-right: var(--space-6);
  }
}

.mb-24 {
  margin-bottom: var(--space-6);
}

.bottom-divider {
  &:not(:last-child) {
    border-bottom: var(--divider-line);
    padding-bottom: var(--space-6);
    margin-bottom: var(--space-6);
  }
}

.top-divider {
  border-top: var(--divider-line);
  padding-top: var(--space-6);
  margin-top: var(--space-6);
}

.pl-24 {
  padding-left: var(--space-6);
}

.column-divider {
  border-right: var(--divider-line);
  margin: 0 var(--space-6);
}

.teaser-wrapper {
  max-width: 725px;

  &:not(:last-child) {
    padding-bottom: 24px;
    border-bottom: var(--divider-line);
    margin-bottom: 24px;
  }
  &:not(:last-child):has(+ .reels-widget) {
    border-bottom: none;
  }

  &:not(:last-child):has(+ .separator-group.has-content) {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
  }

  //border-bottom color for the block before bundle
  &:not(:last-child):has(+ .bundle-wrapper),
  // border-bottom color for the bundle block itself
  &.bundle-wrapper {
    // Exclude inverted bundles, they should not have dark border color
    &:not(:has(.teaser-bundle.inverted)),
    &:not(:has(+ .bundle-wrapper)) {
      border-color: var(--c-shade-50);
    }
  }
}

.teaser-wrapper.double {
  display: flex;
  gap: 48px;
}

.content-main-container__column-main {
  #vev-historie-magasinpluss,
  #vev-historie-ap-archive {
    width: calc(100% + $gapStandard + 209px);
  }
}
